import { FetcherError } from '@core/mainframe-js-sdk';
import { ValidationError } from 'yup';

type APIErrorData = {
  code: string;
  location: string;
  message: string;
  errors?: Array<{ code: string; location: string; message: string }>;
};

type GetErrorMessageProps = { response?: { data: APIErrorData }; message?: string | null } | null;

/** @public */
export function extractAPIError(data: APIErrorData) {
  if (data.hasOwnProperty('errors')) {
    return data.errors.map((err) => err.message).join('. ');
  }

  return data.message;
}

function extractErrors(e: ValidationError) {
  if (e.inner?.length) {
    return e.inner.map((error) => [error.path, error.errors[0]]);
  }
  return e.errors;
}

export async function extractErrorsFromYup(callback: () => Promise<any>) {
  try {
    await callback();
    return [];
  } catch (e) {
    if (e instanceof ValidationError) {
      return extractErrors(e);
    }
    /**
     * Do Not swallow other errors, since it may happen due to something else.
     * And our existing validation system doesn't know how to deal with the
     * situation.
     */
    throw e;
  }
}

export function getErrorMessage(err?: GetErrorMessageProps) {
  if (!err) return;
  // Read the message from mainframe service using fetcher error response
  // message, otherwise fallback to normal error message
  if (err instanceof FetcherError) {
    return extractAPIError(err.body);
  }

  // Read the message from mainframe service error response message,
  // otherwise fallback to normal error message
  return err.response?.data ? extractAPIError(err.response.data) : err.message;
}

function isErrorWithExceptions(exceptions: Array<string>, error: FetcherError | unknown) {
  if (!error) return false;
  return !exceptions.includes(getErrorMessage(error));
}

export function isErrorGetCardV1RewardsBalance(error: FetcherError | unknown) {
  return isErrorWithExceptions(['first spend date not found for card account', 'no rewards amount found'], error);
}

export function isErrorGetCardV1Rewards(error: FetcherError | unknown) {
  return isErrorWithExceptions(['first spend date not found for card account'], error);
}
