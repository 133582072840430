export const YOU_SURE_WAN_TO_CLOSE = 'YOU_SURE_WAN_TO_CLOSE';
export const SELECT_THE_ACCOUNT_YOU_WANT_TO_TRANSFER_ON = 'SELECT_THE_ACCOUNT_YOU_WANT_TO_TRANSFER_ON';
export const CONFIRM = 'CONFIRM';
export const SUCCESS = 'SUCCESS';

export const WIZARD_STEPS = [
  { id: YOU_SURE_WAN_TO_CLOSE, order: 1 },
  { id: SELECT_THE_ACCOUNT_YOU_WANT_TO_TRANSFER_ON, order: 2 },
  { id: CONFIRM, order: 3 },
  { id: SUCCESS, order: 4 },
];
