import { SubmitButton } from '@/components/buttons-main/submit-button';
import { ControlledDropdown } from '@/components/form/components/form-inputs/controlled-dropdown';
import { PlaidLinkAccountModal } from '@/components/plaid-link-account-modal';
import { WizardStep } from '@/components/wizard';
import { PATH_SUPPORT } from '@/constants/routes';
import {
  DepositAccountClosureReasonType,
  useGetAccountDetailsDepositAccountQuery,
  useGetDepositAccountByIdQuery,
  useGetDepositAccountsQuery,
  useInitiateDepositAccountClosureWithoutTransferMutation,
  useInitiateDepositAccountClosureWithTransferMutation,
} from '@/generated/graphql';
import { toAmountInDollarsFromCents } from '@/helpers/currency';
import { getErrorMessage } from '@/helpers/error-handling';
import { useAccountsForSelect } from '@/hooks/use-accounts-for-select';
import { isExternal } from '@/routes/account/routes/credit/routes/[cardId]/rewards/components/redeem-cashback-side-sheet/helprers';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CloseDepositAccountProvider } from './components/close-deposit-account-provider';
import { ClosedAccountSuccessArt } from './components/closed-account-success-art';
import { CONFIRM, SELECT_THE_ACCOUNT_YOU_WANT_TO_TRANSFER_ON, SUCCESS, YOU_SURE_WAN_TO_CLOSE } from './constants';
import { useCloseDepositAccountContext } from './hooks/use-close-deposit-account-context';

type CloseDepositAccountProps = {
  onClose: () => void;
  account: { id: string; balanceInCents: number };
};

function YouSureYourWanToClose() {
  const controller = useCloseDepositAccountContext();
  return (
    <div className="flex flex-col justify-between font-normal px-6 pt-8 h-full">
      <div className="flex flex-col gap-2">
        <h3 className="text-md font-medium">Are you sure you want to close your account?</h3>
        <p className="mb-4 text-base">
          We are sorry to see you go, here are the following steps we`ll take you through before we can confirm the
          closure of your account
        </p>
        <p className="text-base">
          Please not that once you confirm the closure of your account, this action{' '}
          <span className="font-medium">CANNOT</span> be undone
        </p>
        {!!controller.error && <p className="text-sm text-red-800">{controller.error}</p>}
      </div>
      <div className="flex flex-col w-full mb-24">
        <p className="text-sm font-normal mb-4">
          See problems with this transaction?{' '}
          <Link href={PATH_SUPPORT} className="text-blue-800">
            Contact Support.
          </Link>
        </p>
        <div className="flex ml-auto gap-4">
          <button
            onClick={controller.onClose}
            className="rounded-md border-2 font-medium h-fit border-gray-200 py-2.5 px-3 w-fit"
          >
            Cancel
          </button>
          <SubmitButton
            onClick={
              controller.state.externalAccountRequired ? controller.nextStep : controller.onSkipExternalAccountStep
            }
            label="Continue"
            color="blue"
            className="text-md rounded-md m-0 w-full px-3 max-w-full disabled:bg-blue-dark/60"
            loading={controller.isLoading}
            disabled={!!controller.error}
          />
        </div>
      </div>
    </div>
  );
}

export const selectTheAccountToSchema = yup.object().shape({
  externalAccountID: yup.string().required('Choose external account!').nullable(),
});

function useSelectTheAccountToTransferOn() {
  const controller = useCloseDepositAccountContext();
  const accounts = useAccountsForSelect();

  const form = useForm({
    defaultValues: { externalAccountID: '' },
    mode: 'onChange',
    resolver: yupResolver(selectTheAccountToSchema),
  });

  function onNextStep() {
    const selectedExternalAccount = accounts.getExternalAccount(form.getValues('externalAccountID'));
    controller.setExternalAccountData({
      externalAccountID: form.getValues('externalAccountID'),
      externalAccountLastFour: selectedExternalAccount.externalAccountLastFour,
      externalRoutingNumber: selectedExternalAccount.externalRoutingNumber,
      externalAccountNickname: selectedExternalAccount.externalAccountNickname,
    });
    controller.nextStep();
  }

  return {
    form,
    externalAccounts: accounts.verifiedAccountsByMember.filter(isExternal),
    onClose: controller.onClose,
    onNextStep,
    account: controller.account,
    state: controller.state,
  };
}

function SelectTheAccountToTransferOn() {
  const controller = useSelectTheAccountToTransferOn();
  return (
    <div className="flex flex-col justify-between font-normal px-6 pt-8 h-full">
      <div className="flex flex-col gap-2">
        <h3 className="text-md">
          Please select the account you would like to transfer your balance of{' '}
          <span className="font-medium">{toAmountInDollarsFromCents(controller.state.accountBalancesSumInCents)}</span>{' '}
          + <span className="font-medium">{toAmountInDollarsFromCents(controller.state.accruedBalanceInCents)}</span> of
          accrued interest to:
        </h3>
        <ControlledDropdown
          name="externalAccountID"
          label="To external bank account"
          placeholder="Select account"
          options={controller.externalAccounts}
          error={controller.form.formState.errors?.externalAccountID?.message}
          control={controller.form.control}
        />
        <PlaidLinkAccountModal withInitialTrigger>
          <div className="text-blue text-base">+ Link an external account</div>
        </PlaidLinkAccountModal>
      </div>
      <div className="flex flex-col w-full mb-24">
        <p className="text-sm font-normal mb-4">
          See problems with this transaction?{' '}
          <Link href={PATH_SUPPORT} className="text-blue-800">
            Contact Support.
          </Link>
        </p>
        <div className="flex ml-auto gap-4">
          <button
            onClick={controller.onClose}
            className="rounded-md border-2 font-medium h-fit border-gray-200 py-2.5 px-3 w-fit"
          >
            Cancel
          </button>
          <SubmitButton
            onClick={controller.onNextStep}
            label="Continue"
            color="blue"
            className="text-md rounded-md m-0 w-full px-3 max-w-full disabled:bg-blue-dark/60"
            disabled={!controller.form.formState.isValid}
          />
        </div>
      </div>
    </div>
  );
}

function useConfirmAndCloseAccount() {
  const controller = useCloseDepositAccountContext();
  const getDepositAccounts = useGetDepositAccountsQuery();
  const getAccountDetails = useGetAccountDetailsDepositAccountQuery();
  const getDepositAccountById = useGetDepositAccountByIdQuery({ id: controller.account.id });

  const initiateDepositAccountClosureWithTransferMutation = useInitiateDepositAccountClosureWithTransferMutation({
    onSuccess() {
      setTimeout(() => {
        getDepositAccounts.refetch();
        getAccountDetails.refetch();
        getDepositAccountById.refetch();
      }, 3000);
    },
  });
  const initiateDepositAccountClosureWithoutTransferMutation = useInitiateDepositAccountClosureWithoutTransferMutation({
    onSuccess() {
      setTimeout(() => {
        getDepositAccounts.refetch();
        getAccountDetails.refetch();
        getDepositAccountById.refetch();
      }, 3000);
    },
  });

  async function onConfirmAndClose() {
    if (controller.state.externalAccountRequired) {
      await initiateDepositAccountClosureWithTransferMutation.mutateAsync({
        externalAccountId: controller.state.externalAccountID,
        depositAccountId: controller.account.id,
        closureReasons: [
          {
            type: DepositAccountClosureReasonType.CustomerRequest,
            description: '',
          },
        ],
      });
    } else {
      await initiateDepositAccountClosureWithoutTransferMutation.mutateAsync({
        depositAccountId: controller.account.id,
        closureReason: [
          {
            type: DepositAccountClosureReasonType.CustomerRequest,
            description: '',
          },
        ],
      });
    }

    controller.nextStep();
  }

  return {
    state: controller.state,
    onClose: controller.onClose,
    onConfirmAndClose,
    account: controller.account,
    errorMessage:
      getErrorMessage(initiateDepositAccountClosureWithTransferMutation.error) ||
      getErrorMessage(initiateDepositAccountClosureWithoutTransferMutation.error),
    isLoading:
      initiateDepositAccountClosureWithTransferMutation.isLoading ||
      initiateDepositAccountClosureWithoutTransferMutation.isLoading,
  };
}

function ConfirmAndCloseAccount() {
  const controller = useConfirmAndCloseAccount();
  return (
    <div className="flex flex-col justify-between font-normal px-6 pt-8 h-full">
      <div className="flex flex-col gap-4 text-base">
        {controller.state.externalAccountRequired && (
          <>
            <h3 className="text-md font-medium">Confirm account details</h3>
            <p>
              Please confirm the information below is correct. Once you select confirm, we can not reverse the account
              closure.
            </p>
            <p>We will be transferring your funds and earned interest for the month to the following account:</p>
            <div className="flex justify-between">
              <span className="font-medium">Account nickname</span>
              <span>{controller.state.externalAccountNickname.replace(/ ending in \d+$/, '')}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium">Account number last four</span>
              <span>•••• {controller.state.externalAccountLastFour}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium">Routing number</span>
              <span>{controller.state.externalRoutingNumber}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium">Balance</span>
              <span>{toAmountInDollarsFromCents(controller.state.accountBalancesSumInCents)}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium">Accrued Interest</span>
              <span>{toAmountInDollarsFromCents(controller.state.accruedBalanceInCents)}</span>
            </div>
          </>
        )}
        <p>
          Once the account is closed, you can still access your documents such as statements and <b>1099s</b> by logging
          back into <b>HMBradley</b> and going to your account details section.
        </p>
        {!!controller.errorMessage && (
          <div className="flex flex-col gap-1">
            <p className="text-base font-normal text-red-700 mt-6">Something went wrong</p>
            <p className="text-base font-normal text-red-700 mt-1">{controller.errorMessage}</p>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full mb-24">
        <p className="text-sm font-normal mb-4">
          See problems with this transaction?{' '}
          <Link href={PATH_SUPPORT} className="text-blue-800">
            Contact Support.
          </Link>
        </p>
        <div className="flex ml-auto gap-4">
          <button
            onClick={controller.onClose}
            className="rounded-md border-2 font-medium h-fit border-gray-200 py-2.5 px-3 w-fit"
          >
            Cancel
          </button>
          <SubmitButton
            onClick={controller.onConfirmAndClose}
            loading={controller.isLoading}
            label="Confirm and close account"
            color="red"
            className="text-md rounded-md m-0 w-full px-3 max-w-full disabled:bg-blue-dark/60"
          />
        </div>
      </div>
    </div>
  );
}

function Success() {
  const controller = useCloseDepositAccountContext();
  return (
    <div className="flex flex-col justify-between font-normal px-6 pt-8 h-full">
      <div className="flex flex-col gap-4 text-base">
        <h3 className="text-md font-medium">Your account has been closed.</h3>
        <ClosedAccountSuccessArt />
        <p>
          You can still access your documents such as statements and 1099s by logging back into HMBradley and going to
          your account details.
        </p>
      </div>
      <div className="flex flex-row w-full mb-24">
        <div className="flex ml-auto gap-4">
          <button
            className="rounded-md border-2 font-medium h-fit border-gray-200 py-2.5 px-3 w-fit"
            onClick={controller.onClose}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export function CloseDepositAccount(props: CloseDepositAccountProps) {
  return (
    <CloseDepositAccountProvider onClose={props.onClose} account={props.account}>
      <WizardStep step={YOU_SURE_WAN_TO_CLOSE} component={YouSureYourWanToClose} />
      <WizardStep step={SELECT_THE_ACCOUNT_YOU_WANT_TO_TRANSFER_ON} component={SelectTheAccountToTransferOn} />
      <WizardStep step={CONFIRM} component={ConfirmAndCloseAccount} />
      <WizardStep step={SUCCESS} component={Success} />
    </CloseDepositAccountProvider>
  );
}
