import { DepositAccount } from '@/generated/graphql';
import { ExternalAccount } from '@/hooks/use-external-accounts';

export type CreditAccount = {
  accountNumber: string;
  accountType: 'Credit';
  balances: {
    currentBalance?: string;
    lastStatementBalance?: string;
    availableBalance?: string;
    minimumDue?: string;
    totalCycleToDatePayment?: string;
    nextPaymentDueDate?: string;
    currentCycleDate?: string;
    remainingStatementBalance?: string;
  };
  id: string;
  interestRate?: string;
  isActive?: string;
  isNewCustomer: boolean;
};

export type ExternalAccountWithType = {
  account: ExternalAccount;
  type: 'EXTERNAL_ACCOUNT';
  isExternal: true;
};

export type NewDepositWithType = {
  account: DepositAccount & {
    hasIncomingTransferLimit: boolean;
    hasOutgoingTransferLimit: boolean;
    maxReceiveAmount: number;
    maxSendAmount: number;
  };
  type: 'LEDGER';
  isExternal: false;
};

export type PlaidAccountWithType = {
  account: ExternalAccount;
  type: 'PLAID';
  isExternal: true;
};

export type ExternalAccountsById = {
  [id: string]: ExternalAccount;
};

export enum AccountOptionLabel {
  HMBradleyHatch = 'HMBradley - Hatch Bank',
  HMBradleyNYCB = 'HMBradley',
}
