import cx from 'clsx';
import Link from 'next/link';
import COLORS from '../../../styles/colors';
import { MEDIUM, Spinner } from '../../loaders/spinner';

export const SHORT_MODIFIER = 'button-submit-short';
const DEFAULT_MODIFIER = 'button-submit-default';

enum BG_COLORS {
  black = 'bg-black',
  blue = 'bg-blue',
  white = 'bg-white',
  purple = 'bg-purple',
  grey = 'bg-grey',
  red = 'bg-red-dark',
}

type BgColors = 'black' | 'blue' | 'white' | 'purple' | 'grey' | 'red';

type SubmitButtonProps = {
  disabled?: boolean;
  isWarning?: boolean;
  label?: string;
  toNext?: string | { pathname: string; query: { [key: string]: string } };
  loading?: boolean;
  modifier?: string;
  onClick?: () => void;
  className?: string;
  testId?: string;
  color?: BgColors;
};

export function SubmitButton({
  label = 'Submit',
  loading = false,
  disabled = false,
  toNext = null,
  onClick = null,
  modifier = DEFAULT_MODIFIER,
  color = 'black',
  isWarning = false,
  className = '',
  testId = null,
}: SubmitButtonProps) {
  const rootClass = cx('button-submit', modifier, className, {
    'hover:bg-opacity-90': !disabled,
    'cursor-default bg-black-inactive hover:bg-black-inactive': disabled || loading,
    'bg-red': isWarning,
    [BG_COLORS[color]]: !isWarning || !disabled || !loading,
  });

  const child = loading ? <Spinner size={MEDIUM} color={COLORS.white} /> : label;

  if (toNext && typeof toNext === 'string') {
    return (
      <Link href={toNext} className={rootClass}>
        {child}
      </Link>
    );
  }

  if (toNext && typeof toNext === 'object') {
    return (
      <Link
        href={{
          pathname: toNext.pathname,
          query: toNext.query,
        }}
        className={rootClass}
      >
        {child}
      </Link>
    );
  }

  const buttonType = onClick === null ? 'submit' : 'button';

  return (
    <button
      type={buttonType}
      className={rootClass}
      onClick={onClick}
      onKeyPress={onClick}
      disabled={disabled || loading}
      data-testid={testId}
    >
      {child}
    </button>
  );
}
