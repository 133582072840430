import * as React from 'react';
import { CloseDepositAccountContext } from '../contexts/close-deposit-account-context';

export function useCloseDepositAccountContext() {
  const value = React.useContext(CloseDepositAccountContext);

  if (value === undefined) {
    throw new Error('Close Deposit Account Context is undefined');
  }

  return value;
}
