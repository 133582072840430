import * as React from 'react';
import { WizardContext } from './contexts';
import { useWizard } from './hooks/use-wizard';
import { useWizardContext } from './hooks/use-wizard-context';
import { WizardStep as WizardStepType, WizardStepId } from './types';

export { useWizardContext };

type WizardProps = React.PropsWithChildren<{
  steps: WizardStepType[];
  initialStep: WizardStepId;
}>;

export function Wizard(props: WizardProps) {
  const value = useWizard(props);
  return <WizardContext.Provider value={value}>{props.children}</WizardContext.Provider>;
}

type WizardStepProps = {
  step: WizardStepId;
  component: React.ComponentType<any>;
};

export function WizardStep(props: WizardStepProps) {
  const wizardContext = useWizardContext();
  return wizardContext.isCurrentStep(props.step) ? <props.component /> : null;
}
