import { useFeatureWithUserStrategies } from '@/components/feature-flag/hooks/use-feature-with-user-strategies';
import { FEATURE_FLAG_PLAID_INTEGRATION } from '@/constants/features';
import { DepositAccount, DepositAccountType, useGetDepositAccountsForPaymentQuery } from '@/generated/graphql';
import { groupWithoutUnverifiedAccountOption, isNotPlaidAccount, isOpenNycbAccount, isPlaidAccount } from '@/helpers';
import { toAmountInDollarsFromCents } from '@/helpers/currency';
import { ExternalAccount, useExternalAccounts } from '@/hooks/use-external-accounts';
import { accountNameWithBalanceCents } from '@/routes/account/helpers';
import { AccountOptionLabel, ExternalAccountsById } from '@/types/accounts';
import React from 'react';

function getNycbAccountOptions(account: DepositAccount) {
  return {
    label: accountNameWithBalanceCents(
      account.accountType === DepositAccountType.Joint ? 'Joint' : 'Deposit',
      account.depositAccountNumber.lastFour,
      account.depositAccountBalances.availableBalanceInCents,
    ),
    value: account.id,
    isVerified: true,
  };
}

function externalAccountToOption(account: ExternalAccount) {
  return {
    label:
      account.externalAccountNickname +
      (account.availableBalanceInCents !== null
        ? ` (${toAmountInDollarsFromCents(account.availableBalanceInCents)})`
        : ''),
    value: account.id,
    isVerified: account.isVerified,
  };
}

export function useAccountsForSelect() {
  const plaid = useFeatureWithUserStrategies(FEATURE_FLAG_PLAID_INTEGRATION);

  const externalAccounts = useExternalAccounts();

  const externalAccountsByID = externalAccounts.externalAccounts.filter(isNotPlaidAccount).reduce((acc, account) => {
    acc[account.id] = account;
    return acc;
  }, {} as ExternalAccountsById);
  const plaidAccounts = externalAccounts.externalAccounts.filter(isPlaidAccount);

  const getDepositAccountsForPayment = useGetDepositAccountsForPaymentQuery(
    {},
    {
      select(data) {
        return {
          options: data?.depositAccounts.filter(isOpenNycbAccount).map(getNycbAccountOptions) ?? [],
          depositAccounts: data?.depositAccounts.filter(isOpenNycbAccount) ?? [],
        };
      },
    },
  );

  function getNycbAccount(id: string) {
    return getDepositAccountsForPayment.data.depositAccounts.find((a) => a.id === id);
  }

  type Options = { label: string; value: string; isVerified: boolean }[];
  const accountsByMember = React.useMemo(() => {
    const plaidByInstitution =
      plaidAccounts.reduce((institutionMap: { [key: string]: Options }, account) => {
        if (account.plaidInstitutionName in institutionMap) {
          institutionMap[account.plaidInstitutionName].push(externalAccountToOption(account));
        } else {
          institutionMap[account.plaidInstitutionName] = [externalAccountToOption(account)];
        }
        return institutionMap;
      }, {}) ?? {};

    const manullyLinkedAccounts = {
      label: 'Manually linked',
      options: Object.values(externalAccountsByID ?? {}).map(externalAccountToOption),
    };

    return [
      {
        label: AccountOptionLabel.HMBradleyNYCB,
        options: getDepositAccountsForPayment.data?.options ?? [],
      },
      ...(plaid.isEnabled && plaidAccounts
        ? Object.keys(plaidByInstitution).map((institutionName) => ({
            label: institutionName,
            options: plaidByInstitution[institutionName],
          }))
        : []),
      manullyLinkedAccounts,
    ];
  }, [externalAccounts.externalAccounts, plaid.isEnabled]);

  const accountOptions = [
    ...Object.values(externalAccountsByID ?? {}).map(externalAccountToOption),
    ...(plaidAccounts ?? []).map(externalAccountToOption),
    ...(getDepositAccountsForPayment.data?.options ?? []),
  ];

  function isExternalAccount(accountId: string) {
    return !!(externalAccountsByID[accountId] || plaidAccounts.find((account) => account.id === accountId));
  }

  function getExternalAccount(accountId: string) {
    return externalAccountsByID[accountId] || plaidAccounts.find((account) => account.id === accountId);
  }

  return {
    getExternalAccount,
    getNycbAccount,
    isExternalAccount,
    deleteExternalAccount: externalAccounts.deleteExternalAccount,
    editExternalAccountNickname: externalAccounts.editExternalAccountNickname,
    externalAccountsByID,
    accountsByMember,
    verifiedAccountsByMember: accountsByMember.map(groupWithoutUnverifiedAccountOption),
    depositAccounts: getDepositAccountsForPayment.data?.depositAccounts ?? [],
    plaidAccounts,
    accountOptions,
    isLoadingExternalAccounts: externalAccounts.isLoading,
    refetch: externalAccounts.refetch,
  };
}
