import { Drawer } from '@/components/drawers';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { CloseDepositAccount } from './index';

type CloseDepositAccountWithSidebarProps = {
  account: { id: string; balanceInCents: number };
  onDrawerClose: () => void;
  isOpen: boolean;
};

export function CloseDepositAccountWithSidebar(props: CloseDepositAccountWithSidebarProps) {
  return (
    <Drawer onDrawerClose={props.onDrawerClose} open={props.isOpen} anchor="right">
      <Dialog.Panel className="sm:m-2 sm:rounded-lg w-screen sm:w-full h-screen sm:max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
        <Dialog.Title
          as="div"
          className="flex justify-between font-medium leading-6 text-sm text-grey bg-white-dark p-lg"
        >
          <p className="uppercase">Close Account</p>
          <XMarkIcon className="w-lg cursor-pointer" onClick={props.onDrawerClose} />
        </Dialog.Title>
        <div className="w-screen sm:max-w-md h-full">
          <CloseDepositAccount onClose={props.onDrawerClose} account={props.account} />
        </div>
      </Dialog.Panel>
    </Drawer>
  );
}
