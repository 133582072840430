import { Wizard } from '@/components/wizard';
import { DepositAccount } from '@/generated/graphql';
import { WIZARD_STEPS, YOU_SURE_WAN_TO_CLOSE } from '../constants';
import { CloseDepositAccountContext } from '../contexts/close-deposit-account-context';
import { useCloseDepositAccount } from '../hooks/use-close-deposit-account';

type CloseDepositAccountProps = {
  onClose: () => void;
  account: { id: string; balanceInCents: number };
};

function CloseDepositAccount(props: React.PropsWithChildren<CloseDepositAccountProps>) {
  const controller = useCloseDepositAccount({ onClose: props.onClose, account: props.account });

  return <CloseDepositAccountContext.Provider value={controller}>{props.children}</CloseDepositAccountContext.Provider>;
}

export function CloseDepositAccountProvider(props: React.PropsWithChildren<CloseDepositAccountProps>) {
  return (
    <Wizard steps={WIZARD_STEPS} initialStep={YOU_SURE_WAN_TO_CLOSE}>
      <CloseDepositAccount {...props} />
    </Wizard>
  );
}
