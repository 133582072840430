import * as React from 'react';
import { WizardContext } from '../contexts';

export function useWizardContext() {
  const value = React.useContext(WizardContext);

  if (value === undefined) {
    throw new Error('Wizard Context is undefined');
  }

  return value;
}
