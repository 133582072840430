import * as React from 'react';
import { WizardStep, WizardStepId } from '../types';

type UseWizardProps = {
  steps: WizardStep[];
  initialStep: WizardStepId;
};

function byStepId(stepId: WizardStepId) {
  return (step: WizardStep) => {
    return step.id === stepId;
  };
}

function sortByOrder(stepOne: WizardStep, stepTwo: WizardStep) {
  return stepOne.order - stepTwo.order;
}

function getId(step: WizardStep) {
  return step.id;
}

export function useWizard(props: UseWizardProps) {
  const indexes = React.useMemo(() => props.steps.sort(sortByOrder).map(getId), [props.steps]);
  const findStepById = React.useCallback(
    (stepId: WizardStepId) => {
      const step = props.steps.find(byStepId(stepId));

      if (process.env.NODE_ENV !== 'production') {
        if (step === undefined) {
          throw new Error(`"${stepId}" step does not exists in the wizard configuration`);
        }
      }

      return step;
    },
    [props.steps],
  );
  const [currentStep, setCurrentStep] = React.useState(() => findStepById(props.initialStep));

  function nextStep() {
    const nextIndex = indexes.indexOf(currentStep.id) + 1;

    if (nextIndex >= indexes.length) {
      return;
    }

    const nextStep = props.steps[nextIndex];
    setCurrentStep(nextStep);
  }

  function goToStep(stepId: WizardStepId) {
    setCurrentStep(findStepById(stepId));
  }

  function prevStep() {
    const prevIndex = indexes.indexOf(currentStep.id) - 1;

    if (prevIndex < 0) {
      return;
    }

    const prevStep = props.steps[prevIndex];
    setCurrentStep(prevStep);
  }

  function isCurrentStep(stepId: WizardStepId) {
    if (process.env.NODE_ENV !== 'production') {
      findStepById(stepId);
    }

    return stepId === currentStep.id;
  }

  return {
    currentStep,
    nextStep,
    prevStep,
    goToStep,
    isCurrentStep,
  };
}
