export function ClosedAccountSuccessArt() {
  return (
    <svg width="100%" height="200" viewBox="0 0 400 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="360" height="200" rx="8" fill="#1A2428" />
      <rect x="93" y="81" width="160" height="72" rx="8" fill="#006982" />
      <g opacity="0.7">
        <rect x="105" y="93" width="28" height="28" rx="14" fill="white" />
        <path
          d="M116.333 108.333V110.333M119 108.333V110.333M121.667 108.333V110.333M113 113H125M113 105.667H125M113 103.667L119 101L125 103.667M113.667 105.667H124.333V113H113.667V105.667Z"
          stroke="#006982"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M140 98C138.343 98 137 99.3431 137 101C137 102.657 138.343 104 140 104H184C185.657 104 187 102.657 187 101C187 99.3431 185.657 98 184 98H140ZM140 110C138.343 110 137 111.343 137 113C137 114.657 138.343 116 140 116H167C168.657 116 170 114.657 170 113C170 111.343 168.657 110 167 110H140Z"
          fill="white"
        />
      </g>
      <g opacity="0.7">
        <rect x="208" y="135" width="6" height="6" rx="3" fill="white" />
        <rect x="217" y="135" width="6" height="6" rx="3" fill="white" />
        <rect x="226" y="135" width="6" height="6" rx="3" fill="white" />
        <rect x="235" y="135" width="6" height="6" rx="3" fill="white" />
      </g>
      <g filter="url(#filter0_d_5_3616)">
        <circle cx="244" cy="77" r="40" fill="#EB5F2D" />
        <g filter="url(#filter1_d_5_3616)">
          <rect
            x="258.895"
            y="54.4408"
            width="11.3744"
            height="53.0806"
            rx="5.6872"
            transform="rotate(45 258.895 54.4408)"
            fill="white"
          />
        </g>
        <g filter="url(#filter2_d_5_3616)">
          <rect
            x="221.36"
            y="62.4028"
            width="11.3744"
            height="53.0806"
            rx="5.6872"
            transform="rotate(-45 221.36 62.4028)"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_5_3616"
          x="184"
          y="21"
          width="120"
          height="120"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.153125 0 0 0 0 0.241028 0 0 0 0 0.2625 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_3616" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_3616" result="shape" />
        </filter>
        <filter
          id="filter1_d_5_3616"
          x="213.718"
          y="50.7965"
          width="60.8651"
          height="60.8651"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.592157 0 0 0 0 0.192157 0 0 0 0 0.0470588 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_3616" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_3616" result="shape" />
        </filter>
        <filter
          id="filter2_d_5_3616"
          x="213.716"
          y="50.7156"
          width="60.8651"
          height="60.8651"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.592157 0 0 0 0 0.192157 0 0 0 0 0.0470588 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_3616" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_3616" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
