import { AccountOptionLabel } from '@/types/accounts';

export function isExternal(item: { label: string }) {
  return item.label !== AccountOptionLabel.HMBradleyHatch && item.label !== AccountOptionLabel.HMBradleyNYCB;
}

export function isInternal(item: { label: string }) {
  return item.label == AccountOptionLabel.HMBradleyHatch || item.label == AccountOptionLabel.HMBradleyNYCB;
}

export function removeEmptyAccount(item: {
  label: string;
  options: {
    label: string;
    value: string;
    isVerified: boolean;
  }[];
}) {
  return item.options.length > 0;
}
