import { useWizardContext } from '@/components/wizard';
import { useCustomerExternalAccountCheckMutation } from '@/generated/graphql';
import { getErrorMessage } from '@/helpers/error-handling';
import * as React from 'react';
import { CONFIRM } from '../constants';

export function useCloseDepositAccount(props: {
  onClose: () => void;
  account: { id: string; balanceInCents: number };
}) {
  const customerExternalAccountCheckMutation = useCustomerExternalAccountCheckMutation();
  const [state, setState] = React.useState({
    externalAccountID: '',
    externalAccountLastFour: '',
    externalRoutingNumber: '',
    externalAccountNickname: '',
    externalAccountRequired: false,
    accountBalancesSumInCents: 0,
    accruedBalanceInCents: 0,
  });
  const wizardController = useWizardContext();

  function setExternalAccountData({
    externalAccountID,
    externalAccountLastFour,
    externalRoutingNumber,
    externalAccountNickname,
  }: {
    externalAccountID: string;
    externalAccountLastFour: string;
    externalRoutingNumber: string;
    externalAccountNickname: string;
  }) {
    setState((state) => ({
      ...state,
      externalAccountID,
      externalAccountLastFour,
      externalRoutingNumber,
      externalAccountNickname,
    }));
  }

  function onSkipExternalAccountStep() {
    wizardController.goToStep(CONFIRM);
  }

  React.useEffect(() => {
    async function fetchData() {
      const res = await customerExternalAccountCheckMutation.mutateAsync({ depositAccountId: props.account.id });
      setState((state) => ({
        ...state,
        externalAccountRequired: res.customerExternalAccountCheck.externalAccountRequired === 'true',
        accountBalancesSumInCents: res.customerExternalAccountCheck.accountBalancesSumInCents,
        accruedBalanceInCents: res.customerExternalAccountCheck.accruedBalanceInCents,
      }));
    }
    fetchData();
  }, [props.account.id]);

  return {
    error: getErrorMessage(customerExternalAccountCheckMutation.error),
    isLoading: customerExternalAccountCheckMutation.isLoading,
    nextStep: wizardController.nextStep,
    onSkipExternalAccountStep,
    prevStep: wizardController.prevStep,
    onClose: props.onClose,
    account: props.account,
    setExternalAccountData,
    state,
  };
}
